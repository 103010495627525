import { NavigationEnd } from '@angular/router';
import { ResourcePage } from 'src/app/enums/auth2/resources';
import { BreadcrumbPart } from '../breadcrumb.state';

export class Breadcrumb {
  /**
   *
   * @param event
   * @returns
   */
  static getParts = (event: NavigationEnd): BreadcrumbPart[] =>
    event?.urlAfterRedirects
      .split('/')
      .filter((part) => !!part)
      .map((part) => {
        const name = part
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        const resorce = ResourcePage.getResourceRelatedTo(`/${part}`);
        return resorce ? { name: resorce.name, route: resorce.route } : { name, route: null };
      });

  /**
   *
   * @param parts
   * @param name
   * @returns
   */
  static setLastPart = (
    original: BreadcrumbPart[] = [],
    name?: string,
    route: string = null,
  ): BreadcrumbPart[] => {
    // Original array cannot be modified
    const parts = [...original];
    if (parts.length <= 1 || !name) {
      return parts;
    }
    // If remove and replace last element
    parts.pop();
    parts.push({ name, route });
    return parts;
  };
}
