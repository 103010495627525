import { createReducer, on } from '@ngrx/store';
import {
  clearPage,
  setBreadcrumbDetails,
  setBreadcrumbLastPart,
  setBreadcrumbParts,
  setBreadcrumbSticky,
} from '../actions/breadcrumb.actions';
import { BreadcrumbState } from '../breadcrumb.state';
import { Breadcrumb } from './breadcrumb';

export const initialState: BreadcrumbState = {
  parts: [],
};

export const breadcrumbReducer = createReducer(
  initialState,
  on(
    setBreadcrumbParts,
    (state, { event }): BreadcrumbState => ({ details: null, parts: Breadcrumb.getParts(event) }),
  ),
  on(
    setBreadcrumbLastPart,
    (state, { name, route }): BreadcrumbState => ({
      ...state,
      parts: Breadcrumb.setLastPart(state.parts, name, route),
    }),
  ),
  on(setBreadcrumbDetails, (state, details): BreadcrumbState => ({ ...state, details })),
  on(setBreadcrumbSticky, (state, { sticky }): BreadcrumbState => ({ ...state, sticky })),
  on(clearPage, (): BreadcrumbState => initialState),
);
